<template>
    <div class="wrapper" style="margin-top: 5px;">    
      <div class="flex-column">
        <transition leave-active-class="animated bounceOut">
          <div v-if="checkNotificationPermission() && !loading" class="container-fluid" style="color: red;">
              <div class="row no-gutters">
                <div class="col-10 offset-1 center mb-10" style="padding: 10px 0px;">
                  <small class="strong">ORDER ALERTS ARE DISABLED</small> &nbsp;
                  <button @click="askNotificationPermission" class="btn-success">ENABLE</button>
                </div>
              </div>            
          </div>
        </transition>     

        <!-- ACCOUNT ACTIONS -->
        <div class="container card-lite material-card">
            <div class="row no-gutters align-items-center"  style="padding-bottom:10px;">
                <div class="col-11">
                    <h2>
                        {{ user.displayName }} <br>
                        <small class="grey" style="font-weight:300;">
                            <small><small>{{ user.email }} </small> 
                            </small>
                        </small>
                    </h2>                    
                </div>
                <div class="col-1">
                    <i v-if="!accountOptions" @click="showAccountOptions()" class="icon-down-open"></i>
                    <i v-else @click="accountOptions=false" class="icon-up-open animated"></i>                    
                </div>
            </div>
            <div v-show="accountOptions" class="animated fadeIn">
              <div class="row no-gutters center">
                <div class="col-4" >
                  <div style="padding: 10px 0px;border-radius: 7px;background-color: #f5f6fa;">
                    <router-link to="/saved-meals">
                      <div><i class="icon-food"></i></div>
                      <small style="line-height: 25px;">SAVED MEALS</small> <br>
                    </router-link>
                  </div>
                </div>
                <div class="col-4">
                  <div style="background-color: #f5f6fa;padding: 10px 0px;border-radius: 7px;">                  
                    <router-link to="/change-delivery-address">
                      <div><i class="icon-home"></i></div>
                       <small style="line-height: 25px;">ADDRESSES</small> <br>
                    </router-link>
                  </div>
                </div>
                <div class="col-4">
                  <div style="background-color: #f5f6fa;padding: 10px 0px;border-radius: 7px;x;">                  
                    <router-link to="/account/orders">
                      <div>
                        <div class="icon-history"></div>
                      </div>
                      <small style="line-height: 25px;">PAST ORDERS</small>
                    </router-link>
                  </div>
                </div>                                
              </div>
                <br>  
              <div class="col-12 card-lite">
                  <div class="flex-row space-between">
                    <button class="remove-btn-styles text-primary" @click="goHome">Change Location</button>
                    <button class="remove-btn-styles text-primary" @click="signOut()">Sign Out</button></div>                
              </div>
            </div>
        </div>                
                          
        <div v-if="isOrderFailed" class="container material-card card">
          <div class="row no-gutters">
            <div class="col-12">
              <h2 class="center text-primary">
              <i class="icon-cancel-circle"></i> &nbsp;
              LAST ORDER UNSUCCESSFUL
              </h2>
              <br>
              <p>
                <strong>Possible Reason: </strong>
                  <em v-if="!!order.statusMessage">{{ order.statusMessage }} </em>
                  <em v-else> Unknown</em>

              </p>
              <p v-if="true"> 
                <p>                  
                </p>
                <div style="margin:30px 0;">
                <strong class="bg-white material-card" style="padding: 10px;border-radius: 7px;">
                  <small>Order # {{ order.oid }}</small>
                </strong>                                  
                </div>                
                <div style="margin:30px 0;">
                <strong class="bg-white material-card" style="padding: 10px;border-radius: 7px;">
                  <small>Payment Ref # {{ order.paymentOrderId }}</small>
                </strong>                                                       
                </div>                
                <p>
                  If any money has been deducted it would be safely refunded back to your account. To know more you may also call us.
                </p>                  
                <div>
                  <a href="tel:917578025236" data-rel="external" class="btn-wide bg-success text-white margin-top-5" style="display:inline-block;">
                    <i class="icon-phone-circled"></i>
                  </a>
                </div>
            </div>
          </div>
        </div>
        <template v-else>
          <div v-if="order.paymentStatus == 'PENDING'" class="center" style="padding:0 25px;padding-bottom:15px;">
            <h2>Awaiting Payment ...</h2>
            <p>Payment is necessary in order to confirm the order. Ignore if already paid. </p>
            <!-- <button style="max-width:250px;" class="btn-wide text-white bg-success card-lite" @click="$router.push({ path:'/payment', query: { oid: order.oid, total: order.total } })">PAY NOW</button> -->
              <button @click="formOrderText" class="remove-btn-styles btn-wide btn-success" style="max-width:450px;">
                MAKE PAYMENT
              </button>
          </div>
          <template v-else>
            <p v-if="order.statusMessage" class="center"> 
              <em>{{ order.statusMessage }} </em>
            </p>
          </template>
        <!-- <p v-else-if="order.orderStatus == 'PROCESSING'" class="center">
          <em>Processing usually completes within 20 mins</em>
        </p>           -->
          <!-- CURRENT ORDER INFO -->
          <div v-if="objectEmpty(order)" class="container">
            <div style="position: relative;top:10px;">
              <div class="flex-row align-items-center space-around">
                <strong class="bg-white material-card" style="padding: 10px;border-radius: 7px;">
                  Order # {{ order.oid }}
                </strong>
                <strong class="bg-white material-card" style="padding: 10px;border-radius: 7px;">
                   Total: &#8377;{{ order.total }}
                </strong>
                <strong class="bg-white material-card" style="padding: 10px;border-radius: 7px;">
                   {{ order.paymentStatus.toUpperCase() }}
                </strong>              
              </div> 
            </div> 
          </div>
          <div v-if="objectEmpty(order)" class="container material-card card" style="padding-top: 20px;padding-bottom:10px;"> 
            <div class="row no-gutters align-items-center"> 
              <div class="col-5">
                <div class="center animated" v-bind:class="{ 'pulse': flipper }" style="overflow-x: hidden;margin: 20px 0px;">
                  <img v-if="order.orderStatus == 'PROCESSING'" style="padding: 10px;border-radius: 20px;background-color: #f5f6fa;" width="100px;" src="./../assets/received.png" alt="">
                  <img v-if="order.orderStatus == 'PREPARATION'" style="padding: 10px;border-radius: 20px;background-color: #f5f6fa;" width="100px;" src="./../assets/whisk.png" alt="">      
                  <img v-if="order.orderStatus == 'OUT FOR DELIVERY'" style="padding: 10px;border-radius: 20px;background-color: #f5f6fa;" width="100px;" src="./../assets/delivery-man.png" alt="">                      
                  <img v-if="order.orderStatus == 'FAILED'" style="padding: 10px;border-radius: 20px;background-color: #f5f6fa;" width="100px;" src="./../assets/no-sweets.png" alt="">                                    
                </div>
                <div class="text-center">
                  <small>Need Any Help?</small>
                  <div class="flex-row space-around align-items-center margin-top-10">
                    <a href="tel:6001736718" data-rel="external" class="remove-btn-styles bg-default" style="display:inline-block;padding:10px 20px;border-radius:8px;">                      
                      CALL
                    </a>
                    |                                        
                    <a href="https://wa.me/+917578025236" target="_blank" style="display:inline-block">
                      <img width="30px" src="@/assets/whatsapp.png" alt="">  
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-7 heading-text" style="margin-top: 20px;">  
                <div class="row no-gutters">
                  <div class="col-12 order-pending" v-bind:class="{'progress-success text-success': checkOrder('PROCESSING') || checkOrder('PREPARATION') || checkOrder('OUT FOR DELIVERY')}" >
                    <h4 style="margin-top: 0px;">
                      <div class="row no-gutters">
                        <div class="col-1"><i v-if="checkOrder('PROCESSING') || checkOrder('PREPARATION') || checkOrder('OUT FOR DELIVERY')" class="icon-ok-circle"></i></div>
                        <div class="col-10" style="padding-left:7px;">RECEIVED</div>
                      </div>
                    </h4>
                  </div>
                  <div class="col-12 progress-pending" v-bind:class="{'progress-success text-success': checkOrder('PREPARATION') || checkOrder('OUT FOR DELIVERY')}">
                    <h4 style="margin-top: 0px;">
                      <div class="row no-gutters">
                        <div v-if="checkOrder('PREPARATION') || checkOrder('OUT FOR DELIVERY')" class="col-1"><i class="icon-ok-circle"></i></div>
                        <div class="col-10" style="padding-left:7px;">PREPARATION</div>
                      </div>
                    </h4>
                  </div>
                  <div class="col-12 progress-pending" v-bind:class="{'progress-success text-success': checkOrder('OUT FOR DELIVERY')}">
                    <h4 style="margin-top: 0px;">
                      <div class="row no-gutters">
                        <div v-if="checkOrder('OUT FOR DELIVERY')" class="col-1"><i class="icon-ok-circle"></i></div>
                        <div class="col-10" style="padding-left:7px;">OUT FOR DELIVERY</div>
                      </div>
                    </h4>
                  </div>                                
                </div>                
              </div>
              <div class="col-6 center margin-top-10" style="padding:7.5px;">  
                <button class="btn-wide text-white bg-primary card-lite" style="margin-bottom:0;" @click="showModal = true">VIEW ORDER ITEMS</button>
              </div>
              <div v-if="order.paymentStatus !== 'PENDING'" class="col-6 center margin-top-10" style="padding:7.5px;">  
                <button class="btn-wide text-white bg-primary card-lite bg-dark" style="margin-bottom:0;" @click="fetchTransactionDetails()">VIEW RECEIPT</button>
              </div>
            </div>  
          </div>
          <div class="center" v-if="!orderInfoLoaded" style="padding:20px;">
            <beat-loader></beat-loader>
          </div>
          <rate-order v-else :user="user"></rate-order>        
        </template>
      </div>
    
    <!-- Show Message Modal -->
        <modal v-if="showMessage">
          <div slot="header">
            
          </div>
          <div slot="body">
            
          </div>
          <div slot="footer">
            
          </div>
        </modal>

        <modal v-if="showModal" @close="showModal = false">
       
          <div slot="header">
            <h3>Order # {{ order.oid }}</h3>              
          </div>
          <div slot="body">
              <div class="flex-row center-spaced bg-default"  v-for="(item,index) in order.items" v-bind:key="index" style="margin-bottom: 10px;padding: 10px;border-radius: 5px;">
                   <span style="flex-basis: 20%; padding-right: 10px;">
                    <img :src="item.url" style="width:100%; max-height:50px;object-fit: contain;border-radius: 12px;" alt="">
                  </span>
                  <span style="flex-basis: 65%;"> {{ item.name }} ({{ item.flavour }}, {{ item.weight }} ) x {{ item.qty }} </span>  
                  <span style="flex-basis: 15%;" class="text-right">  &#8377;{{ item.price }} </span>
              </div>        
          </div>
          
           
        </modal> 
        <modal v-if="transactionModal" @close="transactionModal = false">
       
          <div slot="header">
            <h3 style="margin-bottom: 5px">Order #{{ order.oid }}</h3>
            <span> {{ order.orderedOn }}</span>  
          </div>
          <div slot="body">
            <template v-if="!!transactionDetails.paymentId">
              <div class="flex-row center-spaced bg-default" style="margin-bottom: 10px;padding: 10px;border-radius: 5px;">
               <span style="flex-basis: 80%;"> Transaction Id: </span>  
                <span style="flex-basis: 20%;" class="text-right"> {{ transactionDetails.paymentId }} </span>
             </div> 
              <div class="flex-row center-spaced bg-default" style="margin-bottom: 10px;padding: 10px;border-radius: 5px;">
               <span style="flex-basis: 80%;"> Transaction Method </span>  
                <span style="flex-basis: 20%;" class="text-right"> {{ transactionDetails.method.toUpperCase() }} </span>
             </div>
            </template> 
              <div class="flex-row center-spaced bg-default" style="margin-bottom: 10px;padding: 10px;border-radius: 5px;">
               <span style="flex-basis: 30%;"> Deliver to </span>  
                <span style="flex-basis: 60%;" class="text-right"> {{ order.address.streetAddress }}, {{ order.address.location }},{{ order.address.pincode }}, {{ order.address.city }} </span>
             </div> 
              <div class="flex-row center-spaced bg-default" style="margin-bottom: 10px;padding: 10px;border-radius: 5px;">
               <span style="flex-basis: 30%;"> Phone </span>  
                <span style="flex-basis: 60%;" class="text-right"> {{ order.notifyTo }} </span>
             </div>              
          </div>   
           <div class="text-right" slot="footer" style="margin-top-10">
            <span class="icon-price text-dark">Total </span> &#8377; {{ order.total }}
          </div>
        </modal> 
                
    </div>  
</template>

<script>

import {bus, messaging} from './../main'
// import {bus} from './../main'
import { db, fieldValue } from './../database'
import imageLoadMixin from './../imageLoadMixin'
import NotificationMixin from './../mixins/NotificationMixin'
import Modal from './Modal'
import BeatLoader from 'vue-spinner/src/BeatLoader.vue'
import RateOrder from './RateOrder'
import cartMixin from '../cartMixin'

export default {
   metaInfo: {    
      title: "Your Account",
      meta: [
        {
          vmid: "robots",
          name: "robots",
          content: "noindex",
        },
      ],
  },
  props: ['user', 'cart'],
  data(){
      return {
        order: {},
        accountOptions: false,
        token: '',
        loading: false,
        showModal: false,
        flipper: false,
        showMessage: false,
        orderInfoLoaded: false,
        transactionModal: false,
        transactionDetails: null
      }
  },
  created() {
    // DEFINITIONS
    const thisRef = this

    bus.$emit("triggerPWAInstall")
    
    // EVENTS
    this.$on('close', () => {
      this.showModal = false
    })   
    bus.$on('tokenSentToServer', status => {
      if(status) { 
          this.$toasted.success("Notifications Enabled")
      }
      else { 
          this.$toasted.error("Something Went Wrong.")
      }
    }) 

    // FETCHING THE ORDER
    let ordersRef = db.doc("placedOrders/"+this.user.uid)
    ordersRef.onSnapshot((doc) => {
      this.orderInfoLoaded = true
      if(doc.exists) {
        // let data = doc.data()
        // if(data.paymentStatus == "PENDING") {
        //   this.accountOptions = true            
        //   return 
        // }
        if(doc.data()['orderStatus'] != 'PROCESSING' ) { 
          this.order = {}
        }     
        thisRef.order = doc.data()
        this.loadAllImages()
      } else {
        for (var member in this.order) delete this.order[member];
         this.accountOptions = true  
      } 
    })
    setInterval(this.flipNow, 2000)    
  },  
  mounted(){
    bus.$emit('unsetUserNotificationEvent')
    // check if notification persomission is granted
    if('Notification' in window) {
      if(Notification.permission == "granted") {
        this.getMessagingToken()
        .then(token => {        
            this.token = token
        })
        .catch(e => console.log(e.message))
      }
    }
  },
  computed: {
    isOrderFailed() {
      if(this.order.length == 0) {
        return false
      }
      return this.order.orderStatus == 'FAILED' || this.order.isVerified == false
    }
  },
  methods: {
    formOrderText() {      
      let msg = `Request for payment | Order No. ${this.order.oid}`
      window.open('https://wa.me/+917578025236?text='+msg, '_blank')
    },  
    fetchTransactionDetails(){
      let TransRef = db.doc("users/"+this.user.uid+"/payments/"+this.order.paymentOrderId);
      TransRef.get().then(snap => {        
        this.transactionDetails = snap.data()   
        
        if(this.transactionDetails){
          this.transactionModal = true
        }
      })
      
    },
    goHome(){
      bus.$emit('goHome')
    },    
    askNotificationPermission(){
      this.loading = true
      bus.$emit('askNotificationPermisson')
    },
    checkOrder(status){
      return this.order.orderStatus == status
    },
    getTimeDiff(){
      let diffInSecs = (Date.now() - this.order.orderTime)/1000
      let diffInMins = diffInSecs/60
      return Math.floor(diffInMins)
    },    
    flipNow(){
        this.flipper = !this.flipper
    },
    deleteToken(){      
        if(this.token == null) { return Promise.resolve() }                
        
        return this.deleteMessagingToken()
        .then(res => {          
          return this.deleteTokenFromServer(this.user.uid)
        })
        .catch((err) => {
          console.log(err)
        })
    },          
    deleteTokenFromServer(userId) {
      return db.doc("users/"+userId).update({
        token: fieldValue.arrayRemove(this.token)
      })
    },
    showAccountOptions() {
        this.accountOptions=true
    },
    objectEmpty(order){
      return Object.keys(order).length != 0
    },
    signOut() {
      bus.$emit('show_loading',true)
      this.deleteToken().then(()=>{
        firebase.auth().signOut().then(() => {
          // localStorage.clear()
          this.emptyCart()
          this.$nextTick(() => {
            window.location = '/' 
          })          
        }).catch((error) => {
          console.log(error)
          this.$toasted.error("Network Error")  
        });
      })        
    },
    checkNotificationPermission(){      
      if(!('Notification' in window)) { return false }
      return Notification.permission !== 'granted'      
    }    
  },
  components: {
    'modal': Modal,
    'beat-loader': BeatLoader,
    RateOrder
  },
  mixins: [imageLoadMixin,NotificationMixin,cartMixin]
}
</script>

<style scoped> 
    .progress-success {
      border-left: 3px solid #00A651 !important;
    }

    .progress-pending {
      border-left: 3px solid #f5f6fa;
    }

</style>


