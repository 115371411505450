<template>
  <div class="wrapper" style="min-height: 77vh;">
    <br />
    <div class="container-fluid card center bg-white material-shadow-1">
      <br />
      <div class="center">
        <img src="./../assets/cherryfrost_logo_192.png" alt="" width="100px;" />
      </div>
      <div class="row no-gutters">
        <div class="col-12">
          <!-- <div style="width:30px;height:5px;display:inline-block;margin-bottom:10px;" class="bg-primary"></div>               -->
          <p>Sign into Cherryfrost</p>
        </div>
      </div>
      <div class="row card-pad-lite no-gutters">
        <div class="col-12">
          <!-- <button class="btn-wide text-white" style="background-color:#d34836;" @click="signInWithPopup()">Continue With Google</button> -->
          <div v-if="signInProgress" class="text-center">
            <em>
              Signin in progress ...
            </em>
          </div>
          <button
            v-else
            class="btn-wide text-dark center d-flex align-items-center justify-content-center"
            @click="googleSignIn"
          >
            <img
              src="@/assets/google.png"
              width="20px"
              alt=""
              srcset=""
              style="margin-right:10px"
            />
            Continue With Google
          </button>
        </div>
      </div>
    </div>
    <br />
  </div>
</template>

<script>
// import imageLoadMixin from "./../imageLoadMixin";
import { bus } from "./../main";

export default {
  metaInfo: {
    title: "Sign In",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "Sign in to your cherryfrost account",
      },
    ],
  },
  props: ["loading"],
  data() {
    return {
      name: "",
      email: "",
      password: "",
      isPWAInstalled: false,
      isInsideApp: false,
      signInProgress: false,
    };
  },
  async created() {
    setTimeout(() => {
      bus.$emit("show_loading", false);
    }, 100);

    this.isInsideApp = window.matchMedia("(display-mode: standalone)").matches;

    if ("getInstalledRelatedApps" in window.navigator) {
      const relatedApps = await navigator.getInstalledRelatedApps();
      this.isPWAInstalled = !!relatedApps.length;
    }

    // HANDLE REDIRECT
    firebase
      .auth()
      .getRedirectResult()
      .then((result) => {
        if (result.credential) {
          this.$toasted.show(
            "Logged In Successfully<i class='icon-ok-circle text-success'></i>"
          );
          this.goHome();
        }
      })
      .catch(function(error) {
        var errorMessage = error.message;
        console.log(errorMessage);
        this.$toasted.error("Slow Network? Please try again");
      })
      .finally(() => {
        bus.$emit("show_loading", false);
      });

    if (this.isInsideApp && this.$route.query.direct) {
      console.log("is inside App");
      bus.$emit("show_loading", true);
      this.googleSignIn();
    }
  },
  mounted() {
    if (this.$route.query.direct) {
      this.signInProgress = true;
    } else {
      this.signInProgress = false;
    }
  },
  methods: {
    googleSignIn() {
      this.signInProgress = true;
      bus.$emit("show_loading", true);
      console.log(this.isInsideApp, this.isPWAInstalled);

      if (!this.isInsideApp && this.isPWAInstalled) {
        console.log("opening in pwa");
        window.open("https://www.cherryfrost.in/sign-in?direct=true");
      } else {
        bus.$emit("show_loading", false);
        var provider = new firebase.auth.GoogleAuthProvider();
        console.log("logging in...");
        this.$nextTick(() => {
          firebase.auth().signInWithRedirect(provider);
        });
      }
    },
    signInWithPopup() {
      bus.$emit("show_loading", true);
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: "select_account",
      });
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          console.log("signed in");
          var token = result.credential.accessToken;
          var user = result.user;
          this.$toasted.show(
            "Logged In Successfully<i class='icon-ok-circle text-success'></i>"
          );

          setTimeout(this.goHome, 1000);
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          var email = error.email;
          var credential = error.credential;
          bus.$emit("show_loading", false);
          this.$toasted.error("Slow Network? Please try again");
        });
    },
    goHome() {
      window.location = "/";
    },
  },
  // mixins: [imageLoadMixin],
};
</script>

<style scoped>

.wrapper {
  max-width: 800px;
  margin: 0 auto;
}

input {
  width: 100%;
  margin-bottom: 15px;
}
</style>
