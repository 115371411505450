<template>
	<div>
		<transition leave-active-class="animated bounceOut">
			<div style="max-width:450px;" v-if="!lastOrderRated" class="container-fluid material-card bg-white">
			<div class="row no-gutters">
				<div class="col-12">
				<h3>RATE YOUR LAST ORDER</h3>
				<p>
					<span v-for="(item,index) in items">
						{{ item.name }} x {{ item.qty }} <span v-if="index != items.length-1">,</span>
					</span>
				</p>
				<p>
					<star-rating :active-color="color" :show-rating="false" :star-size="20" v-model="rating"></star-rating>
					<textarea style="width:100%;" v-if="rating>0" v-model="review"  class="margin-top-15" placeholder="Anything you would like to add?"  cols="30" rows="5"></textarea>
				</p>
				<p>
					<button @click="rate()" class="btn-wide bg-primary text-white">DONE</button>
				</p>
				</div>
			</div>
			</div>
		</transition>
	</div>
</template>

<script>
	import {bus} from './../main'
	import {db} from './../database'
	import StarRating from 'vue-star-rating'

	export default {
		props: ['user'],
		data(){
			return {
				items: [],
				rating: 0,
				lastOrderRated: true,
				deliveredOrderDocId: null,
				review: '',
				color: "#00A651"
			}
		},
		created(){			
			db.doc("users/"+this.user.uid).onSnapshot(doc => {
				this.items = doc.data()['lastOrderItems']
				if(this.items) {
					this.lastOrderRated = false
					this.deliveredOrderDocId = doc.data()['deliveredOrderDocId']
				}
				bus.$emit('show_loading', false)
			})
		},
		methods:{
			async rate() {
				this.lastOrderRated = true
				var userRating = {
					value: {
						rating: this.rating,
						review: this.review,
					},					
					deliveredOrderDocId: this.deliveredOrderDocId
				}

				console.log("calling function...")
				var rateOrder = firebase
				.app().functions('asia-east2')
				.httpsCallable("rateOrder");
				
				let res = await rateOrder({ userRating: userRating })					
				console.log(res)
				
				db.doc("users/"+this.user.uid).update({
					lastOrderItems: firebase.firestore.FieldValue.delete()
				})				
				.then(()=>{					
					this.$toasted.show("Order Rated! <i class='icon-ok-circle text-success'></i>")
				})
				.catch(err => {
					this.lastOrderRated = false
					this.$toasted.error("Order Rating Failed!")					
				})
			}
		},
		components: {
			StarRating
		}
	}
</script>